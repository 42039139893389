import { TpReservationData_AccessCodes } from './common.interfaces';

export type Loading = 'idle' | 'pending' | 'succeeded' | 'failed';

export type PropertyBase = {
	_id: string;
	name: string;
	location: {
		city: { _id: string; name: string };
		country: { _id: string; name: string; iso: string };
		state: { _id: string; name: string; iso: string };
	};
	currency: string;
	timezone: string;
	phone?: string;
	type: string;
	slug?: string;
	url?: string;
};

export type PropertyIntegrationBase = {
	_id: string;
	name: string;
	propertyPartner: {
		name: string;
		currencyCode?: string;
	};
	partnerIntegrationId?: string;
	extras?: any[];
	partnerIntegrations?: any[];
};

export interface FormItem {
	_id?: string;
	name: string;
	key: string;
	order: number;
	mandatory?: boolean;
	mandatoryByIntegrations?: boolean;
	tooltip?: string;
	disabled?: boolean;
}

export interface CustomForm {
	_id?: string;
	appId: string;
	propertyId: string;
	name: string;
	group: string;
	formItems: FormItem[];
}

export type AddressReservation = {
	countryId: string;
	countryName: string;
	stateId?: string;
	stateName?: string;
	cityId?: string;
	cityName?: string;
	line?: string;
	countryCode?: string;
};

export type CountryDocument = {
	countryId: string;
	countryName: string;
};

export type GuestReservation = {
	_id?: string;
	id?: string;
	isMain: boolean;
	firstName: string;
	lastName: string;
	birthdate: Date;
	phone: string;
	email?: string;
	address?: string;
	documentTypeId: string;
	documentTypeName: string;
	documentNumber: string;
	photoDocumentFront: string | undefined;
	selfie: string | undefined;
	signedSelfie: string | undefined;
	photoDocumentBack: string | undefined;
	photoDocumentStamp: string | undefined;
	signedPhotoDocumentFront: string | undefined;
	signedPhotoDocumentBack: string | undefined;
	signedPhotoDocumentStamp: string | undefined;
	photoSign: string;
	reasonTrip: string;
	destiny?: AddressReservation;
	provenance?: AddressReservation;
	nationality: AddressReservation;
	totalGuest: number;
	createdAt?: Date;
	updatedAt?: Date;
	interests: string[];
	thirdPartyId?: string;
	state?: 'pending' | 'complete';
	status?: string;
	checkinCompletedAt?: Date;
	geolocation?: any;
	gender?: 'male' | 'female';
	selfieValidation?: boolean;
	countryDocument: CountryDocument;
};

export enum PARTNER {
	CLOUDBEDS = '6364387f9cc192f99879bbed',
	SIIGO = '63f9340af275a92dcc6464c2',
	AIRBNB = '63e132d5f9231c00f1494955',
	FACTURANTE = '64437ccf2e2331462bd60b61',
	FACTURAMA = '645e612191a8a044e09ed0df',
	POS = '647e5c9713b979591eb931b1',
	ALEGRA = '647779e4be8b667e2c50fd4c',
	HUBSPOT = '617c9f9e08a52c0001958a01',
	TTLOCK = '656a057f2782dfc6c68556b1',
	FLEXIPASS = '669040aaba07c2d375205752',
	STAYS = '640778fae6310e8ac1d55b7f',
	HOSTFULLY = '6697e71e1c03031d1bdfce76',
	COLPPY = '66b5521aebd87985d38f4b7f',
	YALE_CONNECT = '66ec586409418d460b904496',
	GUESTY = '640778f3a1b08f01cc317561'
}

export enum APPS {
	CHECKIN_HOST = '64e0085176019860a1a64bb3',
	SIRE_TRA = '63755983daa4cef95db5d226',
	PAYMENT_COLLECT = '635b5c83370446c2585b7818',
	POWERBI_CONNECT = '64fe38fea1aacce2434070f7',
	INVOICE_MANAGER = '6356a12f904a7a71a90056c6',
	SIIGO_CONNECT = '63703fd5b7a852979f10e0d1',
	EFACTURA_SAT = '64011389e2103240c429d662',
	EFACTURA_AFIP = '641cd190107120c7f31e47ab',
	EFACTURA_DIAN = '640113772dfdcaf3b9fe85bd',
	HUBSPOT_CONNECT = '64fe38bda1aacce2434070f6',
	SCAN_ID = '65e8c12a74bd646992ce8030'
}

enum ReservationStatus {
	checkIn = 'check-in',
	checkOut = 'check-out',
	draft = 'draft'
}

export type Reservation = {
	_id?: string;
	status: ReservationStatus;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	checkInDate?: Date;
	checkOutDate?: Date;
	adults: number;
	children: number;
	total: number;
	createdAt?: Date;
	updatedAt?: Date;
	thirdPartyId: string;
	thirdPartySourceId?: string;
	thirdPartyCustomId?: string;
	guests?: GuestReservation[];
	roomName?: string;
	totalGuests?: number;
	roomId?: string;
	statuses?: {
		tra: { status: string; _id: string };
		sire: { status: string; _id: string };
		sireIn: { status: string; _id: string };
		sireOut: { status: string; _id: string };
	};
	arrivalTime?: string;
	departureTime?: string;
	flightNumber?: string;
	departureFlightNumber?: string;
	currency?: string;
	extras?: any[];
	channelSlug?: string;
	channelName?: string;
	email?: string;
	phone?: string;
	rooms?: any[];
	feedback?: string;
	paymentPhoto?: string;
	signedPaymentPhoto?: string;
	minorPermissionImage?: string;
	signedMinorPermissionImage?: string;
	accessCodes: TpReservationData_AccessCodes[];
	enableAccessDate?: Date;
};

export type AppName = {
	es: string;
	en: string;
};

export type FieldExtra = {
	_id?: string;
	slug: string;
	name: string;
	type: string;
	value?: string;
};

export type Field = {
	_id?: string;
	name?: AppName;
	externalId: string;
	externalSlug: string;
	externalName?: string;
	externalType?: string;
	internalSlug: string;
	internalId?: string;
	internalName?: string;
	internalType?: string;
	extras?: FieldExtra[];
	groupName?: string;
	disabled?: boolean;
};

export type FieldMapping = {
	_id?: string;
	propertyId: string;
	partnerIntegrationId: string;
	appId: string;
	apps: string[];
	fields: Field[];
	propertyIntegrationId?: string;
	propertyIntegrationIdTo?: string;
};

export const COLOMBIA_COUNTRY_ID = '63646413b194e6aba110e08b';
export const ARGENTINA_COUNTRY_ID = '63646411b194e6aba110455d';
export const VENEZUELA_COUNTRY_ID = '6364641db194e6aba1150777';
export const MEXICO_COUNTRY_ID = '63646416b194e6aba11267a2';
export const USA_COUNTRY_ID = '6364641cb194e6aba114690d';
export const CANADA_COUNTRY_ID = '63646412b194e6aba110c8c7';
export const PERU_COUNTRY_ID = '63646418b194e6aba112df1d';
export const ECUADOR_COUNTRY_ID = '63646413b194e6aba11107a3';
export const CHILE_COUNTRY_ID = '63646412b194e6aba110d315';
export const BOLIVIA_COUNTRY_ID = '63646412b194e6aba1108f5b';
export const BRAZIL_COUNTRY_ID = '63646412b194e6aba1109408';
export const URUGUAY_COUNTRY_ID = '6364641db194e6aba11504a2';
export const SURINAM_COUNTRY_ID = '6364641ab194e6aba1140678';
export const GUAYANA_COUNTRY_ID = '63646414b194e6aba1115911';
export const CUBA_COUNTRY_ID = '63646413b194e6aba110f282';
export const GUATEMALA_COUNTRY_ID = '63646414b194e6aba1119cc2';
export const PUERTO_RICO_COUNTRY_ID = '63646418b194e6aba1133a2a';
export const PORTUGAL_COUNTRY_ID = '63646418b194e6aba1132fa8';
export const PANAMA_COUNTRY_ID = '63646418b194e6aba112d7e6';
export const PARAGUAY_COUNTRY_ID = '63646418b194e6aba112ddce';
export const REPUBLICA_DOMINICANA_COUNTRY_ID = '63646413b194e6aba1110518';
export const EL_SALVADOR_COUNTRY_ID = '63646413b194e6aba1110a64';
export const COSTA_RICA_COUNTRY_ID = '63646413b194e6aba110eac1';
export const NICARAGUA_COUNTRY_ID = '63646417b194e6aba112c766';
export const HONDURAS_COUNTRY_ID = '63646415b194e6aba111a264';
export const ESPANA_COUNTRY_ID = '6364641ab194e6aba113c1bd';
export const FRANCIA_COUNTRY_ID = '63646413b194e6aba111128e';
export const ESTADOS_UNIDOS_COUNTRY_ID = '6364641cb194e6aba114690d';
export const JAMAICA_COUNTRY_ID = '63646416b194e6aba1123cfa';
export const TRINIDAD_Y_TOBAGO_COUNTRY_ID = '6364641bb194e6aba1142a67';
export const CURAZAO_COUNTRY_ID = '63646413b194e6aba110f42c';
export const ARUBA_COUNTRY_ID = '63646411b194e6aba1104fbb';
export const BAHAMAS_COUNTRY_ID = '6364641bb194e6aba114296d';
